import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState({...initialState});

  return [
    fields,
    function(event, method) {
      if(!!method && method === "reset") {
        setValues({
          ...fields,
          ...initialState
        })
      } else {
        setValues({
          ...fields,
          [event.target.id]: event.target.value
        });
      }
    }
  ];
}
