import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import "./SCButton.css";

const SCButton = ({
    isLoading,
    text,
    loadingText,
    rtlEnabled = false,
    className = "",
    textClass = "",
    iconClass = "",
    icon = "",
    disabled = false,
    ...props
}) => (
    <ButtonGroup className={`row mx-0 sc-button ${className}`}>
        {(!!icon && icon !== "") && (
            <Button
                className={(rtlEnabled ? "order-last " : "order-first ")
                    + `icon-col icon-part ${iconClass}`}
                disabled={disabled || isLoading}
                {...props}
            >
                <i className={`fa ${icon}`} ></i>
            </Button>
        )}
        {(!!text && text !== "") && (
            <Button
                className={(rtlEnabled ? "order-first " : "order-last ") + ` text-col text-part ${textClass}`}
                disabled={disabled || isLoading}
                {...props}
            >
                {isLoading && <i className="fa fa-spinner fa-spin large-spin-icon"></i>}
                <span className="text-wrap">{!isLoading ? text : ((() => {
                    if (loadingText) {
                        return loadingText;
                    }
                    else {
                        return text;
                    }
                })())}</span>
                {/* <span className="text-wrap">{!isLoading ? text : (loadingText ? loadingText : text)}</span> */}
            </Button>
        )}
    </ButtonGroup>

);

export default SCButton