import React from "react";
import { Button } from "react-bootstrap";
import "./SCButton.css";

const LoaderButton = ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) => (
  <Button
    className={`LoaderButton ${className} ` + ((disabled || isLoading) ? "disabled-loader-btn" : "")}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <i className="fa fa-spinner fa-spin large-spin-icon"></i>}
    {!isLoading ? text : loadingText}
  </Button>
);
export default LoaderButton