import { SC_Routes, SC_Constants } from "./containers/Data";
import { ReactComponent as TelegramPlane } from "./assets/img/paper-plane-solid.svg";
import { ReactComponent as RoundedQuestionMark } from "./assets/img/question-circle-regular.svg";
export default [
  {
    id: 1,
    text: "menu",
    items: [
      {
        id: 1,
        text: SC_Constants.PageHeadings.Home,
        icon: "fa fa-dashboard",
        path: SC_Routes.Dashboards,
      },
      {
        id: 2,
        text: SC_Constants.PageHeadings.Client,
        icon: "fa fa-handshake-o",
        path: SC_Routes.Clients,
      },
      {
        id: 3,
        text: SC_Constants.PageHeadings.Users,
        icon: "fa fa-user",
        path: SC_Routes.UserAcc,
      },
    ],
  },
  {
    id: 2,
    text: "tools",
    items: [
      {
        id: 4,
        text: SC_Constants.PageHeadings.Transactions,
        icon: "fa fa-history",
        path: SC_Routes.Transactions,
      },

      {
        id: 6,
        text: SC_Constants.PageHeadings.QuickStart,
        iconComponent: TelegramPlane,
        path: SC_Routes.QuickStart,
      },
      {
        id: 5,
        text: SC_Constants.PageHeadings.Swagger,
        icon: "fa fa-file-text",
        path: SC_Routes.Swagger,
      },
      {
        id: 7,
        text: SC_Constants.PageHeadings.Documentation,
        iconComponent: RoundedQuestionMark,
        path: SC_Routes.Documentation,
        className: "documentation-tab-icon",
      },
    ],
  },
];
