import React from "react";
import { LoadPanel, Position } from "devextreme-react/load-panel";
import { SC_Constants, SC_Roles } from "../containers/Data";
import "./CustomizedPageActions.css";

export function gridHeader(info) {
  return (
    <div className="row mx-0 column-header">
      <span
        className="col-10 px-0 column-heading-caption"
        title={info.column.caption}
      >
        {info.column.caption}
      </span>
      <span className="col-2 px-0 mx-0 row header-sort-icons">
        {(!info.column.sortOrder || info.column.sortOrder !== "desc") && (
          <i
            className={
              "fa fa-caret-up p-0 col-12 " +
              (!!info.column.sortOrder && info.column.sortOrder === "asc"
                ? "sorted"
                : "")
            }
          ></i>
        )}
        {(!info.column.sortOrder || info.column.sortOrder !== "asc") && (
          <i
            className={
              "fa fa-caret-down p-0 col-12 " +
              (!!info.column.sortOrder && info.column.sortOrder === "desc"
                ? "sorted"
                : "")
            }
          ></i>
        )}
      </span>
    </div>
  );
}

export function sortClientName(data) {
  const sortNames = [...data.Items];
  sortNames.sort((a, b) => {
    return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
  });

  return sortNames;
}

export function handleOptionChange(
  e,
  setDgPageSize,
  setDgPageIndex,
  gridRef,
  paging
) {
  if (e.fullName.endsWith("ortOrder")) {
    if (paging === "none") {
      setTimeout(() => {
        gridRef.repaint();
      }, 30);
    } else {
      gridRef.repaint();
    }
  }
  if (paging !== "none") {
    if (e.fullName === "paging.pageSize") {
      setDgPageSize(e.value);
      setDgPageIndex(0);
    }
    if (e.fullName === "paging.pageIndex") {
      setDgPageIndex(e.value);
    }
  }
}
export function setIntialSorting(gridRef, column) {
  if (!!gridRef && !!gridRef.current && !!gridRef.current.instance) {
    gridRef.current.instance.clearSorting();
    gridRef.current.instance.columnOption(column, "sortOrder", "asc");
  }
}
export function LoaderComponent(id) {
  return (
    <LoadPanel hideOnOutsideClick={false} container={"#" + id} visible={true}>
      <Position my="center" at="center" of={"#" + id} />
    </LoadPanel>
  );
}
export const PageHeader = (heading, props = {}) => {
  const trialExpiryCheck =
    !!props.role &&
    props.role === SC_Roles.QuickStart &&
    !!props.trialVars &&
    !!props.trialVars.userQuickTrialStatus &&
    props.trialVars.userQuickTrialStatus.UserStatus !== "Active";
  return (
    <div className="sc-page-header">
      <h3 className="page-heading">{heading}</h3>
      {trialExpiryCheck &&
        (heading === SC_Constants.PageHeadings.Home ||
          heading === SC_Constants.PageHeadings.QuickStart) && (
          <div className="trial-expiry-msg">
            Your trial has expired and you are limited to reader access. To
            continue using Sentinel Connect,&nbsp;
            <a
              href="https://cpl.thalesgroup.com/software-monetization"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact us
            </a>{" "}
            to subscribe.
          </div>
        )}
    </div>
  );
};
