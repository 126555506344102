import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import React, { useState, useEffect, useCallback } from "react";
import { Auth } from "@aws-amplify/auth";
import AppRoutes from "./AppRoutes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import NavigationData from "./NavigationData";
import logofull from "./assets/img/logo-full.png";
import logomini from "./assets/img/logo-mini.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip, Modal } from "react-bootstrap";
import ChangePassword from "./containers/ChangePassword";
import IdleTimer from "./libs/IdleTimer";
import { SC_Roles, SC_Routes, SC_Api } from "./containers/Data";
import "./App.css";

const App = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [userDDOpen, setUserDDOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [sidebar, toggleSidebar] = useState(false);
  const [changePasswordPopup, toggleChangePasswordPopup] = useState(false);
  const [userQuickTrialStatus, setUserQuickTrialStatus] = useState(null);
  const [trialInProgress, setTrialInProgress] = useState(false);
  const navigate = useNavigate();
  const trialVars = {
    trialInProgress,
    setTrialInProgress,
    userQuickTrialStatus,
    setUserQuickTrialStatus,
    userName,
  };
  useEffect(() => {
    onLoad();
  });

  useEffect(() => {
    const responsiveSideBar = () => {
      if (
        window.innerWidth < 769 &&
        !document.documentElement.classList.contains("nav-toggle")
      ) {
        toggleSidebar(true);
        document.documentElement.classList.toggle("nav-toggle");
      } else if (
        window.innerWidth > 769 &&
        document.documentElement.classList.contains("nav-toggle")
      ) {
        toggleSidebar(false);
        document.documentElement.classList.toggle("nav-toggle");
      }
    };
    responsiveSideBar();
    window.addEventListener("resize", responsiveSideBar);
  }, []);

  async function onLoad() {
    try {
      const tokens = await Auth.currentSession();
      console.log("onload");
      setJwtToken(tokens.getIdToken().jwtToken);
      setUserName(tokens.getIdToken().payload["cognito:username"]);
      setRole(tokens.getIdToken().payload["custom:Role"]);
      if (tokens.getIdToken().payload["custom:Role"] !== SC_Roles.QuickStart) {
        setIsAuthenticated(true);
        setIsAuthenticating(false);
      }
    } catch (e) {
      console.log("error raised at onLoad", e);
      setIsAuthenticating(false);
    }
  }

  useEffect(() => {
    if (role === SC_Roles.QuickStart) {
      setTrialInProgress(true);
      fetch(`${process.env.REACT_APP_SERVICE_API_URL}${SC_Api.quickTrial}`, {
        headers: {
          authorization: jwtToken,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (!!data && data.status === "success") {
            const userStatus = { ...data.userData };
            setUserQuickTrialStatus({ ...userStatus });
            setTrialInProgress(
              !!userStatus &&
              userStatus.UserStatus === "Active" &&
              userStatus.StepStatus < 4
            );
            if (
              !!userStatus &&
              userStatus.UserStatus === "Active" &&
              (userStatus.StepStatus < 4 ||
                (userStatus.usedEMS && userStatus.usedEMS.length < 2))
            ) {
              navigate(SC_Routes.QuickStart);
            }
            setIsAuthenticated(true);
            setIsAuthenticating(false);
          } else {
            throw data;
          }
        })
        .catch(console.log);
    }
  }, [role, jwtToken]);

  const handleLogout = useCallback(async () => {
    await Auth.signOut();
    setRole("");
    setTrialInProgress(false);
    setJwtToken("");
    setIsAuthenticated(false);
    navigate(SC_Routes.Login);
  }, [props]);

  function RenderChangePassword(prop) {
    return (
      <Modal
        {...prop}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="sc-popup change-password-popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePassword onPwdReset={() => toggleChangePasswordPopup(false)} />
        </Modal.Body>
      </Modal>
    );
  }

  useEffect(() => {
    if (isAuthenticated) {
      const timer = new IdleTimer({
        timeout: 30 * 60,
        onTimeout: handleLogout,
        onExpired: handleLogout,
      });
      return () => {
        timer.cleanUp();
      };
    }
  }, [isAuthenticated, handleLogout]);

  function renderSidebar() {
    if (isAuthenticated) {
      return (
        <div className="sidebar menubar" data-color="white">
          <div className="sidebar-wrapper">
            <div className="sidebar-toggle">
              <div className="navbar-toggle">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className="menu-tooltip">
                      {sidebar ? "Expand" : "Collapse"}
                    </Tooltip>
                  }
                >
                  <button
                    type="button"
                    className="navbar-toggler"
                    style={{ marginTop: '10px', marginRight: '10px' }}
                    onClick={() => openSidebar()}
                  >
                    <i
                      className={
                        "fa fa-chevron-" + (sidebar ? "right" : "left")
                      }
                    ></i>
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            {navigationList()}
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
  function navigationList() {
    var i = 1;
    var renderNavItems = (item) => {
      if (item.iconComponent) {
        return (
          <item.iconComponent
            className={"custom-svg-icons " + item.className}
          />
        );
      }
      else {
        return (
          <i className={item.icon}></i>
        )
      }
    }
    return (
      <Nav className="navigation wraplist">
        {NavigationData.map((group) => (
          <div key={"dv-" + i++}>
            {group.items.map((item) => {
              if (
                (role === SC_Roles.QuickStart &&
                  item.path === SC_Routes.UserAcc) ||
                (role !== SC_Roles.QuickStart &&
                  item.path === SC_Routes.QuickStart)
              ) {
                return "";
              } else {
                return (
                  <li className={activeRoute(item.path)} key={"nl-" + i++}>
                    {item.path === SC_Routes.Documentation ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item.path}
                        className="nav-links"
                      >
                        {renderNavItems(item)}
                        <p>{item.text}</p>
                      </a>
                    ) : (
                      <Link
                        to={item.path}
                        className={" nav-link " + activeRoute(item.path) + " "}
                      >
                        {renderNavItems(item)}
                        <p>{item.text}</p>
                      </Link>
                    )}
                  </li>
                );
              }
            })}
          </div>
        ))}
      </Nav>
    );
  }
  function userDDToggle() {
    setUserDDOpen(!userDDOpen);
  }
  function openSidebar() {
    toggleSidebar(!sidebar);
    document.documentElement.classList.toggle("nav-toggle");
  }
  function activeRoute(routeName) {
    const location = useLocation();
    return location.pathname.includes(routeName) || (
      location.pathname === "/" &&
      ((() => {
        if (role === SC_Roles.QuickStart && trialInProgress) {
          return routeName === SC_Routes.QuickStart
        }
        else {
          return routeName === SC_Routes.Dashboards
        }
      })())
    ) ? " active" : "";
  }
  return (
    !isAuthenticating && (
      <div className="App">
        <div className="wrapper">
          {isAuthenticated ? (
            <Navbar
              color="primary"
              expand="lg"
              className="navbar-absolute fixed-top navbar-primary "
            >
              <Container fluid>
                <div className="logo">
                  <a href="/" className="simple-text logo-mini">
                    <div className="logo-img">
                      <img src={logomini} alt="react-logo" />
                    </div>
                  </a>
                  <a href="/" className="logo-full">
                    <img src={logofull} alt="react-logo" />
                  </a>
                </div>
                <div className="navbar-wrapper">
                  <NavbarBrand href="/">Sentinel Connect</NavbarBrand>
                </div>
                <Collapse
                  isOpen={false}
                  navbar
                  className="user-profile-dropdown"
                >
                  <Nav navbar>
                    <Dropdown
                      isOpen={userDDOpen}
                      toggle={() => userDDToggle()}
                      className="userdd"
                      end="true"
                    >
                      <DropdownToggle nav>
                        <div className="d-flex align-items-center" style={{ marginTop: "10px", marginRight: "10px" }}>
                          <FontAwesomeIcon
                            style={{ fontSize: "16px" }}
                            icon={faUserCircle}
                          />
                          <span className="mx-1">{userName}</span>
                          <FontAwesomeIcon
                            style={{ fontSize: "12px", fontWeight: 'bold' }}
                            icon={(() => {
                              if (userDDOpen) {
                                return faAngleUp;
                              }
                              else {
                                return faAngleDown;
                              }
                            })()}
                          />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          tag="a"
                          target="_blank"
                          href="https://docs.sentinel.thalesgroup.com/softwareandservices/connect/release-notes"
                        >
                          What`&apos;s New
                        </DropdownItem>
                        <DropdownItem
                          tag="a"
                          onClick={() => toggleChangePasswordPopup(true)}
                        >
                          Change Password
                        </DropdownItem>
                        <DropdownItem
                          tag="a"
                          className=""
                          href="#"
                          onClick={handleLogout}
                        >
                          Logout
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
          ) : (
            ""
          )}
          {renderSidebar()}
          <RenderChangePassword
            show={changePasswordPopup}
            onHide={() => toggleChangePasswordPopup(false)}
          />
          <div className={isAuthenticated ? "main-panel" : ""}>
            <AppRoutes
              appProps={{
                isAuthenticated,
                jwtToken,
                role,
                sidebar,
                setIsAuthenticated,
                trialVars,
              }}
            />
          </div>
          {isAuthenticated ? (
            <div className="copyright sc-footer">
              &copy; {1900 + new Date().getYear()}{" "}
              <a
                href="https://cpl.thalesgroup.com/software-monetization"
                target="_blank"
                rel="noopener noreferrer"
              >
                Thales Group
              </a>
              .&nbsp;All Rights Reserved |&nbsp;
              <a
                href="https://supportportal.thalesgroup.com/csm"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    )
  );
};

export default App;
