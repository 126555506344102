class IdleTimer {
    constructor({ timeout, onTimeout, onExpired, events }) {
        this.events = (events) ? events : ["click", "input", "load", "mousemove", "scroll", "keydown"];
        this.timeout = timeout;
        this.onTimeout = onTimeout;

        const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
        if (expiredTime > 0 && expiredTime < Date.now()) {
            localStorage.removeItem("_expiredTime");
            onExpired();
            return;
        }
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
    }

    startInterval() {
        this.updateExpiredTime();
        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
            if (expiredTime < Date.now()) {
                if (this.onTimeout) {
                    this.onTimeout();
                    this.cleanUp();
                }
            }
        }, 1000);
    }

    updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
        }, 300);
    }

    tracker() {
        this.events.map((event) => window.addEventListener(event, this.eventHandler));
    }

    cleanUp() {
        clearInterval(this.interval);
        this.events.map((event) => window.removeEventListener(event, this.eventHandler));
    }
}
export default IdleTimer;
