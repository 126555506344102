import React, { useEffect, useState, useRef } from "react";
import { Auth } from "aws-amplify";
import {
  FormGroup,
  FormControl,
  FormLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import SCButton from "../components/SCButton";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./ChangePassword.css";

export default function ChangePassword(props) {
  const [isChanging, setIsChanging] = useState(false);
  const [loginMsg, setloginMsg] = useState("");
  const [fields, handleFieldChange] = useFormFields({
    password: "",
    oldPassword: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const time = useRef(150000);
  const [timeLeft, setTimeLeft] = useState("02:30");
  useEffect(() => {
    const timer = setTimeout(() => {
      if (time.current > 0) {
        time.current = time.current - 1000;
        const timeObj = {
          mins: Math.floor((time.current / (1000 * 60)) % 60).toString(),
          secs: Math.floor((time.current / 1000) % 60).toString(),
        };
        let displayTime =
          (timeObj.mins.length === 1 ? "0" : "") +
          timeObj.mins +
          ":" +
          ((timeObj.secs.length === 1 ? "0" : "") + timeObj.secs);
        setTimeLeft(displayTime);
      }
    }, 1000);

    return () => clearTimeout(timer);
  });
  function validateForm() {
    if (!!props.tempUser && props.tempUser.isTempUser) {
      return (
        fields.password.length > 0 && fields.password === fields.confirmPassword
      );
    } else if (!!props.resetUser && props.resetUser.resetPwd) {
      return (
        fields.confirmationCode.length > 0 &&
        fields.password.length > 0 &&
        fields.password === fields.confirmPassword
      );
    } else {
      return (
        fields.oldPassword.length > 0 &&
        fields.password.length > 0 &&
        fields.password === fields.confirmPassword
      );
    }
  }

  function validationType(type) {
    if (type === "new") {
      return !!props.tempUser && props.tempUser.isTempUser;
    } else if (type === "reset") {
      return !!props.resetUser && props.resetUser.resetPwd;
    } else {
      return (
        (!!props.tempUser && props.tempUser.isTempUser) ||
        (!!props.resetUser && props.resetUser.resetPwd)
      );
    }
  }

  async function handleChangeClick(event) {
    event.preventDefault();
    setIsChanging(true);
    setloginMsg("");
    try {
      if (validationType("new")) {
        await Auth.completeNewPassword(props.tempUser.session, fields.password);
        handleFieldChange({}, "reset");
        props.onPwdReset();
      } else if (validationType("reset")) {
        await Auth.forgotPasswordSubmit(
          props.resetUser.username,
          fields.confirmationCode,
          fields.password
        );
        handleFieldChange({}, "reset");
        props.onPwdReset(fields);
      } else {
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(
          currentUser,
          fields.oldPassword,
          fields.password
        );
        setIsChanging(false);
        props.onPwdReset();
      }
    } catch (e) {
      let message;
      switch (!!e) {
        case e.message.includes(
          "Value at 'previousPassword' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$"
        ):
          message = "Invalid Previous Password";
          break;
        case e.message.includes("Incorrect username or password."):
          message = "Invalid Old Password";
          break;
        case e.message.includes(
          "Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6"
        ):
        case e.message.includes(
          "Value at 'proposedPassword' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$"
        ):
          message = "Password must be at least 10 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.";
          break;
        case e.message.includes("Password not long enough"):
          message = "Password must have at least 10 characters.";
          break;
        case e.message.includes("session is expired."):
          message =
            "Session expired! Please complete the password change process in under 2 min 30 secs. Please try again.";
          break;
        case e.message.includes(
          "Value at 'username' failed to satisfy constraint: Member must have length greater than or equal to 1"
        ):
        case e.message.includes("Invalid verification code provided, please try again."):
          message = "Invalid input. Please try again.";
          break;
        default:
          message = (() => {
            if (e.message) {
              return e.message;
            }
            else {
              return (
                !validationType() ? "Invalid Old Password" : "Password is invalid."
              )
            }
          })()
          break;
      }
      setloginMsg(message);
      setIsChanging(false);
    }
  }
  return (
    <div className={validationType() ? "ResetPassword" : "ChangePassword"}>
      <form onSubmit={handleChangeClick}>
        {validationType() ? (
          <>
            {(() => {
              if (validationType("reset")) {
                return (
                  <>
                    <FormGroup controlId="confirmationCode" style={{ marginBottom: "20px" }}>
                      <FormControl
                        autoFocus
                        placeholder="Confirmation Code"
                        type="text"
                        name="confirmationcode"
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                        autoComplete="new-password"
                      />
                    </FormGroup>
                  </>
                )
              }
              else {
                return (
                  <></>
                )
              }
            })()}
            <FormGroup controlId="password" style={{ marginBottom: "20px" }}>
              <FormControl
                placeholder="New Password"
                type="password"
                value={fields.password}
                name="newpassword"
                autoComplete="new-password"
                onChange={handleFieldChange}
              />
            </FormGroup>
            <FormGroup controlId="confirmPassword" style={{ marginBottom: "20px" }}>
              <FormControl
                placeholder="Confirm Password"
                type="password"
                onChange={handleFieldChange}
                name="confirmpassword"
                autoComplete="new-password"
                value={fields.confirmPassword}
              />
              {validationType("new") && (
                <div
                  className={
                    "d-flex align-items-center ms-2 float-end py-2 px-1 " +
                    ((() => {
                      if (time.current < 30000) {
                        return "failure-msg";
                      }
                      else {
                        return "";
                      }
                    })())
                  }
                >
                  <span className="fa fa-clock-o timer-icon"></span>
                  <span>{timeLeft}</span>
                </div>
              )}
            </FormGroup>
            <LoaderButton
              block
              className="login-submit forgot-submit"
              type="submit"
              text="Change Password"
              loadingText="Changing…"
              disabled={!validateForm()}
              isLoading={isChanging}
            />
            <Button
              variant="link"
              className="simple-btn back-to-lgn"
              type="reset"
              onClick={() => props.backToLogin()}
            >
              Back to Login
            </Button>
          </>
        ) : (
          <>
            <FormGroup as={Row} controlId="oldPassword">
              <FormLabel column sm={4}>
                Old Password:
                <span className="failure-msg">*</span>
              </FormLabel>
              <Col sm={8}>
                <FormControl
                  autoFocus
                  type="password"
                  name="oldpassword"
                  onChange={handleFieldChange}
                  value={fields.oldPassword}
                  autoComplete="new-password"
                />
              </Col>
            </FormGroup>
            <FormGroup as={Row} controlId="password">
              <FormLabel column sm={4}>
                New Password:
                <span className="failure-msg">*</span>
              </FormLabel>
              <Col sm={8}>
                <FormControl
                  type="password"
                  value={fields.password}
                  onChange={handleFieldChange}
                  name="newpassword"
                  autoComplete="new-password"
                />
              </Col>
            </FormGroup>
            <FormGroup as={Row} controlId="confirmPassword">
              <FormLabel column sm={4}>
                Confirm Password:
                <span className="failure-msg">*</span>
              </FormLabel>
              <Col sm={8}>
                <FormControl
                  type="password"
                  onChange={handleFieldChange}
                  name="confirmpassword"
                  autoComplete="new-password"
                  value={fields.confirmPassword}
                />
              </Col>
            </FormGroup>
            <Row className="buttons-row">
              <Col xs={12}>
                <SCButton
                  className="float-end"
                  type="submit"
                  text="Change Password"
                  loadingText="Changing…"
                  disabled={!validateForm()}
                  isLoading={isChanging}
                />
                <Button
                  variant="link"
                  className="sc-cancel float-end"
                  type="reset"
                  onClick={() => props.onPwdReset()}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </>
        )}
        {loginMsg !== "" && <div className="login-msg">{loginMsg}</div>}
      </form>
    </div>
  );
}
